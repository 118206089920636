@import "mixins"
.casinoCompactLayoutItem
  margin-bottom: var(--casino_widgetContainerMargin)

  > div
    @include flex

  &:not(.expand)
    --widgetHeaderHeight: 43px
    &.homeWidget .expandedContent
      background: var(--home_tournamentItemBg)
      border-radius: 4px

    &:has(.casinoCompactLayoutItemHeader span:first-of-type > span + strong)
      --widgetHeaderHeight: 64px
    &:not(.topWinners) .casinoCompactLayoutItemHeader
      min-height: var(--widgetHeaderHeight)
      > span
        width: 100%
        > *:first-child
          max-width: calc(100% - 100px)
      &:has(span:first-of-type > span + strong)
        align-items: flex-start
        > span
          > span
            margin-bottom: 8px
          strong
            font-size: 19px
            line-height: 20px

  .expandedContent
    background: rgb(var(--casino_expandedContentBg))
    border-radius: 12px
    padding-bottom: 6px
    overflow: hidden
    transition: height 0.4s
    width: 100%
    position: relative

  &.expand > div
    position: fixed
    height: 100%
    width: 100%
    top: 0
    z-index: 999999
    left: 0
    justify-content: center

    .expandedContent
      height: 97% !important
      width: 98%
      margin-top: 3%
      box-shadow: 0 0 4px rgba(#000, 0.5)
      overflow: auto

      .casinoCompactLayoutItemHeader
        padding: 4px 10px
        background: rgba(var(--casino_expandedContentBg), 0.9)
        position: sticky
        top: 0
        z-index: 10
        span
          > *:first-of-type
            color: rgba(var(--secondaryColor))
            font-size: 20px
            text-transform: capitalize
            min-height: 34px
            padding: 7px 2px 0px 0
            @include bold
          > *:nth-child(2)
            display: none

      .casinoCompactLayoutItemContent :global(.casino-game-item) > div
        background: var(--casino_expandedContentItemBg)

div:not(.expand):not(.allGamesContainer):not(.narrowVertical)
  > div > .expandedContent > .casinoCompactLayoutItemContent :global(.casino-game-item)
    width: calc(50% - 16px)

    &:first-of-type
      margin-left: 0

    &:last-of-type
      margin-right: 0

.casinoCompactLayoutItemHeader
  @include flex
  padding: var(--casino_widgetHeaderPadding)
  justify-content: space-between

  > span
    display: flex
    flex-direction: column
    overflow: hidden

    > span
      font-size: 14px
      line-height: 16px
      color: rgb(var(--primaryColor))
      @include ellipsis
    strong
      font-size: 20px
      line-height: 21px
      color: rgb(var(--secondaryColor))
      @include ellipsis

.casinoCompactLayoutItemContent
  display: flex
  overflow-x: auto
  overflow-y: hidden
  padding: var(--casino_widgetContentPadding)
  min-height: 192px
  // align-items: flex-start

  & :global
    .loader
      display: flex !important
      background: unset

    .no-game
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      background: var(--casino_noGameBg)
      border-color: var(--casino_noGameBorder)
      margin: 0

  &::-webkit-scrollbar
    display: none

  :global(.casino-game-item)
    margin-top: 0

@media screen and (max-width: 380px) and (orientation: portrait)
  .casinoCompactLayoutItemContent
    min-height: 184px

//mozaic default

.mozaicDefault
  .expandedContent
    height: calc(var(--widgetHeaderHeight) + 286px + 6px)
    .casinoCompactLayoutItemContent
      min-height: 286px

  .casinoCompactLayoutItemContent
    position: relative
    flex-direction: column
    margin-left: 0
    padding: 0
    overflow: hidden
    display: block

    > div:not(:first-of-type)
      margin-top: 8px

    & :global
      .slick-list
        margin: 0 -4px
        .slick-track
          display: flex

      .slick-slider:nth-child(2)
        margin-left: -49px
      .slick-slide
        overflow: hidden
        padding: 0 4px

        > div > span
          background: var(--casino_gameItemBg)
          position: relative
          height: 90px
          overflow: hidden
          border-radius: 14px
          vertical-align: top

          &:after
            content: ""
            position: absolute
            left: 0
            top: 0
            height: 34px
            width: 100%
            background: linear-gradient(to bottom,rgba(var(--secondaryColor), 0.3) 0%,rgba(var(--secondaryColor), 0.01) 100%)

          :global(.imageNext)
            position: relative
            height: 100%

            img
              top: 50%!important
              transform: translateY(-50%)
              height: auto!important

          video
            height: 100%
            object-fit: cover
            width: 100%

//mozaic mix

.mozaicMix
  &.expand :global(.slick-slider)
    width: 100%

  .expandedContent
    height: calc(var(--widgetHeaderHeight) + 292px + 6px)

    .casinoCompactLayoutItemContent
      min-height: 292px

  .casinoCompactLayoutItemContent
    position: relative
    margin-left: 0
    padding: 0
    overflow: hidden
    display: block
    min-height: 0
    min-width: 0

    & :global
      .slick-slide
        overflow: hidden

        > div
          width: 100%
          height: 292px
          display: grid
          grid-gap: 8px
          padding: 0 4px
          position: relative
          grid-template-areas: 'img1 img1 img5 img6''img2 img3 img5 img7''img2 img4 img8 img8'
          > span
            width: 100%!important
            display: flex!important

            &:nth-child(1)
              grid-area: img1
              img
                height: auto!important

            &:nth-child(2)
              grid-area: img2
              img
                width: auto!important
                left: 50%!important
                transform: translateX(-50%)

            &:nth-child(3)
              grid-area: img3
            &:nth-child(4)
              grid-area: img4

            &:nth-child(5)
              grid-area: img5
              img
                width: auto!important
                left: 50%!important
                transform: translateX(-50%)

            &:nth-child(6)
              grid-area: img6
            &:nth-child(7)
              grid-area: img7
            &:nth-child(8)
              grid-area: img8
              img
                height: auto!important

          :global(.imageNext)
            border-radius: 14px
            background: var(--casino_gameItemBg)
            position: relative
            width: 100%
            height: 100%
            overflow: hidden
            img
              height: auto!important

          video
            height: 100%
            object-fit: cover
            width: 100%

//mozaic rain

.mozaicRain
  &.expand .casinoCompactLayoutItemContent
    flex-wrap: wrap
    :global(.slick-list)
      height: 260px!important

  .expandedContent
    height: calc(var(--widgetHeaderHeight) + 292px + 6px)

    .casinoCompactLayoutItemContent
      min-height: 292px

  .casinoCompactLayoutItemContent
    position: relative
    margin: 0
    padding: 0
    overflow: hidden

    > div:has(:global(.slick-slider))
      display: flex
      justify-content: center
      width: 100%
      height: 100%

    & :global
      .slick-track
        top: -4px
      .slick-slider
        flex: 0 0 78px

        &:nth-child(2n) .slick-track
          top: -71px
      .slick-list
        width: 100%
        height: 292px!important
      .slick-slider:nth-child(2n-1) .slick-slide > div > span:nth-child(2)
        height: 88px
        :global(.imageNext)
          position: relative
          height: 100%

          img
            left: 0!important
            top: 50%!important
            transform: translateY(-50%)
            width: 100% !important
            height: auto !important

      .slick-slide
        overflow: hidden
        border-width: 0

        > div
          width: 100%
          position: relative

          > span
            height: 146px
            border: 4px solid transparent
            border-radius: 14px
            overflow: hidden
            display: block!important
            width: 100%!important

          :global(.imageNext)
            position: relative
            background: var(--casino_gameItemBg)
            height: 100%

            img
              left: 50%!important
              transform: translateX(-50%)
              width: auto!important
              height: 150% !important

          video
            height: 100%
            object-fit: cover
            width: 100%

// shop

.shop
  .expandedContent
    padding: 0
    height: calc(var(--widgetHeaderHeight) + 317px)
  .casinoCompactLayoutItemContent
    padding: 0
    min-height: 317px
    margin-left: var(--casino_shopContentMarginLeft)

    > div
      margin-bottom: -10px
      display: flex
      flex-direction: column
      max-height: 327px
      flex-wrap: wrap
      min-width: max-content

    :global(.casino-game-item)
      min-width: 330px
      width: 330px
      max-width: 330px
      height: auto
      justify-content: flex-start
      align-items: flex-start
      padding-bottom: 19px
      margin: 0 12px!important

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3)
        margin-left: 0!important

      > span:first-of-type
        right: unset
        left: 60px
        top: 10px

      > div
        width: 90px
        height: 90px
        position: static
        border: 0
        border-radius: 14px
        &:before
          content: none

        > :global(.svg-icon)
          border-top-left-radius: 13px
          svg
            width: 54px
            height: 54px

        :global(.imageNext)
          img
            position: absolute
            top: 50%
            transform: translateY(-50%)
          &:after
            content: ""
            position: absolute
            left: 0
            top: 0
            height: 34px
            width: 100%
            background: linear-gradient(to bottom,rgba(var(--secondaryColor), 0.3) 0%,rgba(var(--secondaryColor), 0.01) 100%)

        > div
          opacity: 1
          flex-direction: unset
          bottom: 19px
          right: 0
          top: unset
          transform: unset
          left: unset
          padding-left: 100px
          width: 100%

          button
            margin: 0
            min-width: 80px
            box-shadow: unset

            &:first-of-type
              flex: 1

            &:nth-child(2)
              margin-left: 10px

      > section
        top: 0
        height: 44px

        :global(.game-item-name-blur-bg)
          display: none

        > div
          background: transparent
          color: rgb(var(--primaryColor))
          font-weight: unset
          text-transform: capitalize
          font-size: 14px
          padding: 4px 34px 4px 100px
          text-align: left
          > span
            font-size: 16px
            line-height: 18px

          > i
            width: 34px
            height: 34px
            justify-content: flex-end

            &:before
              mask-size: 22px 22px
              width: 22px
              height: 22px
              animation: none
              transition: all 0.4s

      &:after
        content: ""
        position: absolute
        bottom: 9px
        border-bottom: 1px dotted var(--casino_lobbySlotsBorder)
        opacity: 1
        width: calc(100% - 93px)
        height: 1px
        right: -2px

// reels

.reels
  .expandedContent
    height: calc(var(--widgetHeaderHeight) + 170px + 6px)
    .casinoCompactLayoutItemContent
      min-height: 170px

  &.expand
    .casinoCompactLayoutItemContent
      display: grid!important
      grid-template-columns: repeat(5, 1fr)
      min-height: auto
      grid-gap: 4px
      margin: 0 4px 4px!important
      align-items: stretch
      :global(.casino-game-item)
        min-width: auto
        height: auto
        width: 100%!important
        grid-column: auto / span 2
        margin: 0!important
        background: var(--body_bg)
        max-width: inherit!important
        > div
          border-radius: 0
          border: 1px solid #000!important
          *
            border-radius: 0

        &:nth-child(6n - 4),  &:nth-child(6n - 2)
          grid-row: auto / span 2
          align-self: stretch
          grid-column: auto / span 3
          > div
            height: 100%

        &:nth-child(6n - 4)
          grid-column: 3 / 6

        &:nth-child(6n - 2)
          grid-column: 1 / 4

// wide row

.wideRow  .casinoCompactLayoutItemContent
  :global(.casino-game-item)
    max-width: unset !important
    width: auto !important

    > div img
      width: var(--casino_wideRowItemWidth)
      height: var(--casino_wideRowItemHeight)
      position: static
      transform: none

// narrow row
.narrowRow
  &:not(.expand)
    .casinoCompactLayoutItemContent
      min-height: 170px

    :global(.casino-game-item)
      max-width: unset !important
      max-width: unset !important
      width: 122px !important
      margin-right: 1px
      > div
        :global(.imageNext)
          justify-content: center

  .casinoCompactLayoutItemContent
    :global(.casino-game-item)
      :global(.game-item-img-fallback)
        :local(img)
          aspect-ratio: 176 / 246

      > div
        border: 1px solid #000

        &:before
          content: none

        button
          max-width: 90%
          min-width: 90px

      > section
        height: 100%
        bottom: 0
        pointer-events: none

        > div
          text-align: center
          font-size: 10px
          text-transform: capitalize
          background: transparent
          padding: 0 8px 8px
          justify-content: flex-end

          > i
            pointer-events: all
            min-width: 26px
            height: 26px
            top: 0
            right: 0
            background: rgba(0, 0, 0, .4)
            border-top-right-radius: var(--casino_gameItemRadius)
            border-bottom-left-radius: 4px

.topWinners .expandedContent
  padding: var(--casino_widgetContentPadding)

  :global(.no-game)
    min-width: 150px

  .casinoCompactLayoutItemHeader
    padding-right: 0
    padding-left: 0

  .casinoCompactLayoutItemContent
    position: relative
    min-height: calc((var(--casinoCompact_topWinnerHeight) + 6px) * 5)
    padding: 0

.allGamesContainer, .narrowVertical
  margin: 0
  width: 100%

  .expandedContent
    background: transparent
    border-top: 1px dashed var(--casino_allGamesBorder)
    border-radius: 0
    margin-bottom: 0
    margin-top: 4px
    overflow: unset
    height: unset

.seeAll
  margin-left: auto
  min-width: unset
  white-space: nowrap
  height: auto
  @include flex
  background: none
  text-transform: capitalize
  color: var(--casino_seeAllColor)
  font-size: 16px
  line-height: 16px
  font-weight: normal
  padding: 0
  position: absolute
  right: 10px

.close
  min-width: 34px
  height: 34px
  border-radius: 8px
  @include flex
  margin-left: auto
  justify-content: center
  background: var(--casino_closeBg)

  &:before
    content: ""
    width: 12px
    height: 12px
    mask-size: 12px 12px
    background-color: rgb(var(--secondaryColor))
    mask-image: var(--icon-close)
  &:active
    opacity: 0.8

//responsive all items
.allGamesContainer .expandedContent, .casinoCompactLayoutItem:not(.mozaicDefault):not(.narrowRow):not(.mozaicRain):not(.mozaicMix).expand > div .expandedContent
  .casinoCompactLayoutItemContent
    display: grid
    padding: 0 6px 6px
    overflow: hidden
    gap: 10px
    grid-template-columns: repeat(9, 1fr)

    :global(.casino-game-item)
      margin: 0
      width: 100%
      max-width: none
      > div
        max-height: none

  @media screen and (max-width: 1800px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(8, 1fr)

  @media screen and (max-width: 1460px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(7, 1fr)

  @media screen and (max-width: 1280px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(6, 1fr)

  @media screen and (max-width: 1000px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(5, 1fr)

  @media screen and (max-width: 800px) and (orientation: landscape)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(4, 1fr)

  @media screen and (max-width: 650px) and (orientation: landscape)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(3, 1fr)

  @media screen and  (min-width: 801px) and (max-width: 870px)
    .casinoCompactLayoutItemContent
      --casino_gameItemNameHeight: 24px

  @media screen and (max-width: 800px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(4, 1fr)

  @media screen and  (min-width: 663px) and (max-width: 700px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      --casino_gameItemNameHeight: 24px

  @media screen and (max-width: 662px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(3, 1fr)

  @media screen and  (min-width: 481px) and (max-width: 524px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      --casino_gameItemNameHeight: 24px
      --casino_gameItemNameFontSize: 11px

  @media screen and (max-width: 480px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(2, 1fr)

  @media screen and (max-width: 370px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      --casino_gameItemNameHeight: 24px

  @media screen and (max-width: 340px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      --casino_gameItemNameFontSize: 11px

.narrowVertical .expandedContent .casinoCompactLayoutItemContent
  padding: 0 0 6px!important
  :global(.game-item-img-fallback)
    :local(img)
      aspect-ratio: 176 / 246

.narrowVertical .expandedContent, .narrowRow.expand .expandedContent
  .casinoCompactLayoutItemContent
    display: grid
    overflow: hidden
    padding: 0 6px 6px
    gap: 8px
    grid-template-columns: repeat(9, 1fr)

    :global(.casino-game-item)
      margin: 0
      width: 100%
      max-width: none
      > div
        max-height: none
        border: 1px solid #000
        > div
          width: 100%
          > button
            min-width: auto
            max-width: var(--casino_gameItemButtonWidth)
            width: 82%

        &:before
          content: none

      > section
        height: 100%
        bottom: 0
        pointer-events: none

        > div
          text-align: center
          font-size: 10px
          text-transform: capitalize
          background: transparent
          padding: 0 8px 8px
          justify-content: flex-end

          > i
            pointer-events: all
            min-width: 26px
            height: 26px
            top: 0
            right: 0
            background: rgba(0, 0, 0, .4)
            border-top-right-radius: var(--casino_gameItemRadius)
            border-bottom-left-radius: 4px

  @media screen and (max-width: 1260px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(8, 1fr)

  @media screen and (max-width: 1100px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(7, 1fr)

  @media screen and (max-width: 880px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(6, 1fr)

  @media screen and (max-width: 710px)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(5, 1fr)

  @media screen and (max-width: 570px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(4, 1fr)

  @media screen and (max-width: 440px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(3, 1fr)

  @media screen and (max-width: 340px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(2, 1fr)

  @media screen and (max-width: 220px) and (orientation: portrait)
    .casinoCompactLayoutItemContent
      grid-template-columns: repeat(1, 1fr)
